<template>
  <!-- Main content -->
  <section class="content">
    <div class="btn-box"> 
        <el-form :inline="true">
        <el-form-item label="状态：">
            <el-select v-model="status" placeholder="请选择">
                <el-option
                v-for="item in status_list"
                :key="item.type"
                :label="item.name"
                :value="item.type">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="seach(1)">搜索</el-button></el-button> 
        </el-form-item>
        </el-form>
    </div>
    <div class="btn-box"> 
        <el-button type="primary" @click="add">添加</el-button> 
      </div>
    <el-table
        v-loading="loading"
        :data="list"
        border>
      <el-table-column label="编号" prop="id" width="100"></el-table-column>
      <el-table-column label="排序" width="100">
          <template slot-scope="scope">
            <span v-if="!scope.row.edit">{{scope.row.weight}}</span>
            <el-input v-else v-model="scope.row.weight" placeholder="请输入权限"></el-input>
            <el-button type="text" @click="changeWeight(scope.row,scope.$index)">{{!scope.row.edit?'修改':'确认'}}</el-button>
          </template>
</el-table-column>
<el-table-column label="商品主图">
    <template slot-scope="scope">
            <el-image :src="scope.row.item_pic" :preview-src-list="[scope.row.item_pic]"></el-image>
        </template>
</el-table-column>
<el-table-column label="商品标题" prop="item_title"></el-table-column>
<el-table-column label="商品链接" prop="item_url"></el-table-column>
<el-table-column label="商品类型">
    <template slot-scope="scope">
          {{scope.row.type | type}}
        </template>
</el-table-column>
<el-table-column label="开奖次数" prop="total_times"></el-table-column>
<el-table-column label="初始参与次数" prop="used_num"></el-table-column>
<el-table-column label="状态" prop="status">
    <template slot-scope="scope">
        {{scope.row.status | status}}
    </template>
</el-table-column>
<el-table-column label="操作">
    <template slot-scope="scope">
      <el-button type="primary" size="mini" @click="alterDialg(scope.row.id)">修改</el-button></el-button>
      <el-button type="primary" size="mini" @click="manage(scope.row.id)">活动管理</el-button></el-button>
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>

<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="商品类型" v-show="isAlter">
            <el-radio-group v-model="type">
                <el-radio :label="1">真实商品</el-radio>
                <el-radio :label="2">虚拟商品</el-radio>
                </el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="商品标题">
            <el-input v-model="item_title" placeholder="请输入商品标题"></el-input>
        </el-form-item>
        <el-form-item label="商品主图">
            <span class="none">s</span>
            <upLoad v-model="item_pic" id="up" @url="imgUrl"></upLoad>
        </el-form-item>
        <el-form-item label="商品链接">
            <el-input v-model="item_url" placeholder="请输入商品链接"></el-input>
        </el-form-item>
        <el-form-item label="开奖总需设置">
            <el-input v-model="total_times" placeholder="请输入开奖总需设置"></el-input>
        </el-form-item>
        <el-form-item label="已参与次数设置">
            <el-input v-model="used_num" placeholder="请输入开奖总需设置"></el-input>
        </el-form-item>
        <el-form-item label="排序">
            <el-input v-model="weight" type="number" placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item label="虚拟用户" v-if="type == 2">
            <a class="cursor" @click="fakerBtn" v-if="checkFaker.length==0">点击选择虚拟用户</a>
            <div v-else class="faker-box cursor" @click="fakerBtn">
                <div class="faker-item" v-for="item in checkFaker">
                    <img :src="item.avatar">
                    <span>{{item.nick_name}}</span>
                </div>
            </div>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
    <el-dialog title="虚拟用户" :visible.sync="fakerUser" :close-on-click-modal=false append-to-body>
        <el-checkbox-group v-model="fakerList">
            <el-checkbox v-for="item in fakerUserList" :label="item.id" :key="item.id">
                <img :src="item.avatar">
                <span>{{item.nick_name}}</span>
            </el-checkbox>
        </el-checkbox-group>

        <div slot="footer" class="dialog-footer">
            <el-checkbox-button v-model="checked">全选</el-checkbox-button>
            <el-button @click="fakerBtn('close')">取 消</el-button>
            <el-button type="primary" @click="fakerBtn">确 定</el-button>
        </div>
    </el-dialog>
</el-dialog>
</section>
</template>
<script>
    import * as api from '@/config/api'
    export default {
        name: 'activitySnatchTreasureGoods',
        data() {
            return {
                loading: true,
                list: [],
                total: 0,
                page: 1,
                pagesize: 10,
                userPage: 1,
                userPagesize: 100,

                checked: false,

                dialog: false,
                fakerUser: false,
                isAlter: true,

                id: '',
                item_title: '',
                item_pic: '',
                item_url: '',
                type: '',
                weight: '',
                total_times: '',
                used_num: '',

                fakerUserList: [],
                checkFaker: [],
                fakerList: [],
                setList: [],

                status: '',
                status_list: [{
                    type: '',
                    name: '全部'
                }, {
                    type: '1',
                    name: '上架'
                }, {
                    type: '2',
                    name: '已下架'
                }]
            }
        },
        watch: {
            fakerList(val) {
                this.checkFaker = []
                for (let i = 0; i < val.length; i++) {
                    this.fakerUserList.forEach(item => {
                        if (item.id == val[i]) {
                            this.checkFaker.push(item)
                        }
                    })
                }
            },
            checked(val) {
                console.log(val)
                if (val) {
                    this.fakerUserList.forEach(item => {
                        this.fakerList.push(item.id)
                    })
                    return
                }
                this.fakerList = []
            }
        },
        filters: {
            type(val) {
                switch (Number(val)) {
                    case 1:
                        return '真实商品'
                        break;
                    case 2:
                        return '虚拟商品'
                        break;
                }
            },
            status(val) {
                let name
                switch (Number(val)) {
                    case 1:
                        name = '上架'
                        break;
                    default:
                        name = '已下架'
                        break;
                }
                return name
            },
        },
        mounted() {
            this.getSnatchTreasureGoods()
            this.getFakerUserList()
        },
        methods: {
            getSnatchTreasureGoods(page, size) {
                api.getSnatchTreasureGoods({
                    status: this.status,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.list = res.data.list
                    this.list.forEach(item => {
                        item.edit = false
                    });
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            getFakerUserList(id) {
                api.getFakerUserList({
                    page: this.userPage,
                    pagesize: this.userPagesize,
                    id: id ? id : ''
                }, res => {
                    this.checkFaker = []
                    this.fakerList = []
                    this.fakerUserList = res.data.list
                    this.fakerUserList.forEach(item => {
                        if (item.is_exist == 1) {
                            this.fakerList.push(item.id)
                        }
                    })
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getSnatchTreasureGoods()
            },
            currentChange(val) {
                this.page = val
                this.getSnatchTreasureGoods()
            },
            imgUrl(val) {
                this.item_pic = val
            },
            alterDialg(id) {
                this.id = id
                this.isAlter = false
                api.getActItem({
                    id: id,
                }, res => {
                    this.item_title = res.data.item.item_title
                    this.item_pic = res.data.item.item_pic
                    this.item_url = res.data.item.item_url
                    this.type = Number(res.data.item.type)
                    this.weight = res.data.item.weight
                    this.total_times = res.data.item.total_times
                    this.used_num = res.data.item.used_num
                    this.dialog = true
                })
                this.getFakerUserList(id)
            },
            add() {
                this.id = ""
                this.item_title = ''
                this.item_pic = ''
                this.item_url = ''
                this.type = ''
                this.weight = ''
                this.total_times = ''
                this.used_num = ''
                this.checkFaker = []
                this.fakerList = []
                this.isAlter = true
                this.dialog = true
            },
            alter() {
                if (this.id != '') {
                    api.updateActItem({
                        id: this.id,
                        item_title: this.item_title,
                        item_pic: this.item_pic,
                        item_url: this.item_url,
                        weight: this.weight,
                        total_times: this.total_times,
                        user: this.fakerList,
                        used_num: this.used_num,
                    }, res => {
                        this.getSnatchTreasureGoods()
                        this.dialog = false
                    })
                    return
                }
                api.addActItem({
                    item_title: this.item_title,
                    item_pic: this.item_pic,
                    item_url: this.item_url,
                    type: this.type,
                    weight: this.weight,
                    total_times: this.total_times,
                    user: this.fakerList,
                    used_num: this.used_num
                }, res => {
                    this.getSnatchTreasureGoods()
                    this.dialog = false
                })
            },
            manage(id) {
                this.$router.push({
                    name: 'activitySnatchTreasureActivity',
                    query: {
                        id: id
                    }
                })
            },
            fakerBtn(type) {
                this.fakerUser = !this.fakerUser
                if (type == 'close') {
                    this.checked = false
                }
            },
            // 修改权重
            changeWeight(item, index) {
                item.edit = !item.edit
                this.$set(this.list, index, item)
                if (!item.edit) {
                    api.itemSort({
                        id: item.id,
                        weight: item.weight
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        })
                    })
                }
            },
            seach(page, size) {
                this.getSnatchTreasureGoods(page, size)
            }
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .none {
        color: transparent
    }
    
    .el-checkbox {
        margin-bottom: 10px;
        .el-checkbox__input {
            position: absolute;
            top: 0;
            right: 0;
        }
        .el-checkbox__label {
            text-align: center;
            img {
                width: 50px;
                display: block;
                margin: 0 auto;
            }
        }
    }
    
    .el-checkbox-button {
        float: left;
    }
    
    .faker-box {
        display: flex;
        flex-wrap: wrap;
        .faker-item {
            margin-right: 10px;
            text-align: center;
            img {
                display: block;
                margin: 0 auto;
                width: 50px;
            }
        }
    }
    
    .cursor {
        cursor: pointer;
    }
</style>